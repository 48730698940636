.footer-container{
    padding-top: 25px;
    padding-bottom: 10px;
    height: 250px;
    background-color: black;
    color:white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer-row{
    align-self: center;
    display: flex;
    flex-direction: row;
    color:white;
    justify-content: space-evenly;
    align-content: center;
    width:80%;
}
.ftr-logo{
    width: 150px;
    position:absolute;
    left: 20%;
}
.ftr-text{
    align-self: center;
    font-size: 32px;
    margin: 5px;
}
.ftr-ct-row{
    position: absolute;
    right:18%;
    display: flex;
    flex-direction: row;
    align-content: center;
}
.scl-logo{
    height: 90px;
}
.slide-container{
    align-self: center !important;
    width: 90%;
    margin: 25px;
}
.tstml-row-container{
    display: flex !important;
    background-color: black;
    color:white;
    justify-content: space-between;
    align-content: center;
    height: 400px;
}
.tstml-img{
    border-radius: calc(375px / 2);
    width: 375px;
    position: relative;
    left: 10px;
}
.cntnt-col{
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    color:white;
    width: 70%;
}
.cntn-head{
    padding:10px 10px 10px 15px;
    color:white;
    font-size: 32px;
}
.cntn-sub{
    padding:10px;
    margin-left: 25px;
    color:white;
    font-size: 24px;
    font-style: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.pfl-container{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin:75px
}

.pfl-img{
    border:5px solid white;
    width:400px;
}

.pfl-name{
    font-size: 28px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color:white;
    width: 100%;
    text-align: center;

}
.pfl-title{
    font-size: 24px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:white;
    width: 100%;
    text-align: center;
}
.clnt-container{
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: baseline;
    background-color: transparent;
    width: 350px;
    height: fit-content;
    margin:35px;
}
.clnt-txt-col{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    padding-top: 20px;
    position: relative;
}
.clnt-img-container{
    position: relative;
    overflow: hidden;
}
.clnt-img{
    width: 100%;
    border: 4px solid white;
    border-radius: 15px;
}
.clnt-header{
    color:white;
    text-align: center;
    font-size: 40px;
}
.clnt-subtext{
    color:white;
    font-size: 28px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.clnt-overlay{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    cursor:pointer;
    background-color: rgb(0,0,0,.75);
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease;
}

.clnt-img-container:hover .clnt-overlay{
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.clnt-scl-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: rgb(0,0,0,50);
    border: 3px solid grey;
    border-radius: 25px;
}

.clnt-scl-logo{
    width: 100px;
    background-color: transparent;
    padding:20px;
    border-radius: 20px;
}

.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100%;
    border-color: #4b5563;
    width: 100%;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
}

.carousel-card>img {
    width: 300px;
    border-radius: 10%;
}

.srvc-container {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    margin:25px;
    padding:30px;
    width: 600px;
    border: 2px solid transparent;

}

.srvc-container:hover {
    border: 2px solid white;
}

.srvc-img{
    width: 200px;
    height: 200px;
    border-radius: calc(200px/2);
}

.srvc-text-col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 50px;
}

.srvc-text{
    font-size: 32px;
    padding-top: 10px;
    color:white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.srvc-desc{
    font-size: 20px;
    font-style: normal;
    padding-top: 10px;
    color:white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.news-container{
    display:flex;
    position:relative;
    width: fit-content;
    margin:20px;
    border-radius: 15px;
    background-color: white;
}

.news-img{
    max-width: 500px;
    border-radius: 15px;
    background-color: white;
}

.news-caption{
    background-color: white;
    border-radius: 15px;
    font-size: 35px;
    display:none;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: rgb(50,50,75,.85);
    color:white;
}

.news-desc{
    background-color: transparent;
    font-size: 20px;
}

.news-container:hover .news-caption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.news-link{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.hr-break{
    align-self: center;
    margin-top: 20px;
    color: white;
    width: 50%;
}

@media only screen and (max-width:480px){
    .footer-container{
        padding-top: 0px;
        margin-bottom: 55px;
        height: fit-content;
    }
    .ftr-ct-row{
        right:5%;
    }
    .ftr-text{
        font-size: 12px;
    }
    .ftr-logo{
        width: 35px;
        left:10%
    }
    .scl-logo{
        height: 25px;
    }

    .tstml-row-container{
        flex-direction: column;
        height: fit-content;
        justify-content: baseline;
    }
    .tstml-img{
        border-radius: calc(110px /2);
        width: 110px;
        height: 110px;
        align-self: center;
        margin-bottom: 15px;
    }
    .cntn-head{
        padding: 0;
        font-size: 22px;
        margin: 0px 3px 10px 3px;
        text-align: center;
    }
    .cntn-sub{
        padding: 0;
        margin: 0;
        font-size: 18px;
        text-align: center;
    }
    .cntnt-col{
        width: 100%;
        padding:0px;
        margin-left: 0px;
        justify-content: flex-end;
    }
    .slide-container{
        width: 95%;
        padding:0px;
        margin: 0px;
    }
    .pfl-container{
        margin:10px;
    }
    .pfl-img{
        width: 250px;
    }
    .pfl-name{
        margin-top: 15px;
        font-size: 22px;
    }
    .pfl-title{
        font-size: 18px;
    }
    .clnt-container{
        width: 280px;
        position: relative;
        overflow: visible;
    }
    .clnt-txt-col{
        width: 100%;
    }
    .clnt-header{
        font-size: 32px;
    }
    .clnt-subtext{
        font-size: 24px;
    }
    .clnt-overlay{
        position:absolute;
        display: flex;
        opacity: 1;
        height: fit-content;
        width: 100%;
        top:72%;
        background-color: transparent;
    }
    .clnt-scl-row{
        width: 100%;
        align-items: center;
        border-color: transparent;
        justify-content: space-evenly;
        height: 2%;
        background-color: transparent;
    }
    .clnt-scl-logo{
        width: 75px;
        padding:10px;
        border-radius: 10px;
        background-color: rgb(225,225,225,.25);
    }
    .clnt-img-container{
        overflow: visible;
    }
    .clnt-img-container:hover .clnt-overlay{
        position:absolute;
        display: flex;
        opacity: 1;
        height: fit-content;
        width: 100%;
        top:72%;
        background-color: transparent;
    }
    .srvc-container{
        width: 95%;
        padding:20px;
        margin:10px;
        flex-direction: column;
    }
    .srvc-img{
        width: 150px;
        height: 150px;
        border-radius: calc(150px/2);
        align-self: center;
    }
    .srvc-text-col{
        margin-left: 0px;
        justify-content: center;
        align-items: center;
    }
    .srvc-text{
        padding-top: 5px;
        font-size: 25px;
        text-align: center;
    }
    .srvc-desc{
        padding-top: 0px;
        font-size: 19px;
        text-align: center;
    }
    .news-container{
        margin:5px;
    }
    .news-img{
        max-width: 335px;
    }
    .news-caption{
        font-size: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgb(0,0,0,.65);
    }
    .news-desc{
        font-size: 20px;
        font-weight: bold;
        padding-top: 3px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .news-container:hover .news-caption {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}
