
.nav {
    background-color: black;
    position: sticky;
    top: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    color:white;
    height: 125px;
    z-index: 999;
}
.nav-logo{
    position: sticky;
    left:5vw;
    padding:10px;
}
.nav-container{
    position:sticky;
    left:50vw;
}
.button-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 700px;
    height: 125px;
    margin-right: 175px;
}
.nav-item{
    font-size: 24px;
    background-color: black;
    border: none;
    color:white;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
}
.nav-item:hover{
    color:grey;
}

.nav-item-active{
    color:grey;
    font-size: 28px;
    background-color: black;
    border: none;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
}

@media only screen and (max-width:480px) {
    .nav{
        width: 100%;
        height: fit-content;
        justify-content: space-around;
    }
    .nav-container{
        position:static;
        height: 100%;
        width: 75%;
        margin: 10px 0px 10px 0px
    }
    .nav-logo{
        position:static;
        padding:10px;
        width: 55px;
        object-fit: contain;
    }
    .button-row{
        width:100%;
        margin:0px;
        height: auto;
    }
    .nav-item{
        font-size: 12px;
        padding:2px;
    }
    .nav-item-active{
        font-size: 11px;
    }
}