::-webkit-scrollbar {
    display: none
}

.intro-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.home-cv-image {
    width: 100%;
    margin-bottom: 25px;
}

.brand-carousel-L{
    margin:100px;
    border-left:2px solid white;
    border-right:2px solid white;
    height:400px;
}
.brand-carousel-S{
    margin:100px;
    border-left:2px solid white;
    border-right:2px solid white;
    height: 250px;
}

.brand-title{
    text-align:center;
    color:white;
    font-size:62px;
    padding:50px 100px 0px 100px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    opacity: 0;
    cursor: pointer;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: var(--overlay-opacity, 0); /* Initial opacity */
    transition: opacity 0.3s ease; /* Adjust duration and easing as desired */
}

.large-text {
    background-color: black;
    align-self: center;
    text-align: center;
    color: white;
    font-size: 4.1vw;
    width: 70%;
}

.plrs-container {
    background-color: white;
    display: flex;
    min-height: 700px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
}

.plrs-rect {
    height: 475px;
    width: 375px;
    background-color: white;
    border-radius: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin: 10px 25px;
    padding: 0px 15px 0px 15px;

}

.plr-title {
    margin-top: 35px;
    color: black;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    background-color: transparent;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.plr-icon {
    color: black;
    height: 100px;
    width: 100px;
    align-self: center;
    background-color: transparent;
}

.plr-descr {
    display: none;
    color: white;
    align-self: center;
    text-align: center;
    width: 100%;
    font-size: 28px;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.plr-button {
    align-self: center;
    width: fit-content;
    color: black;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: oblique;
    font-weight: bold;
    border: none;
    font-size: 23px;
    margin-bottom: 20px;
}

.plr-button:hover {
    color: yellow !important;
    background-color: transparent;
}

.plrs-rect:hover {
    background-color: rgb(0, 0, 0, .75);
    border: 3px solid black;
}

.plrs-rect:hover .plr-icon {
    display: none;
}

.plrs-rect:hover .plr-descr {
    display: flex;
    color: white;
}

.plrs-rect:hover .plr-title {
    color: white;
}

.plrs-rect:hover .plr-button {
    color: white;
}

.stsf-header {
    text-align: center;
    font-size: 95px;
    color: grey;
}

.stsf-container {
    background-color: black;
    color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
}

.stsf-row {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.stsf-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.stsf-stat {
    font-size: 90px;
}

.stsf-title {
    font-size: 32px;
}

.header-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    justify-content: space-around;
    align-items: center;
    height: 350px;
    margin: 5px 15px 50px 15px;
}

.abt-header-sub {
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 56px;
    text-align: center;
    width: 90%;
}

.company-container {
    padding: 100px 15px 85px 15px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.company-title {
    background-color: white;
    color: black;
    text-align: center;
    font-size: 67px;
    font-style: normal;
    font-weight: bolder;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.desc-container {
    border: 3.5px solid black;
    background-color: #0a213f;
    padding: 25px;
    border-radius: 20px;
    width: 50%;
    margin: 50px;
    margin-right: 15px;
    color: white;
    font-size: 26px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.simple-row {
    margin: 30px 0px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.simple-column {
    padding-left: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.desc-text {
    padding: 15px;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.video-frame {
    height: 600px;
    position: sticky;
    bottom: 0px;
}

.ceo-container {
    background-color: black;
    position: relative;
    color: white;
    width: 100%;
}

.sticky-description {
    padding-top: 50px;
    position: absolute;
    left: 5vw;
    width: 50%;
}

.ceo-title {
    font-size: 64px;
    padding: 20px;
    color: white;
}

.team-title{
    text-align: center;
    font-size: 64px;
    padding: 20px;
    color: white;
    padding-top: 50px;
}

.ceo-name {
    font-size: 46px;
    padding: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}

.ceo-text {
    font-size: 28px;
    padding: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    width: 55%;
}

.ceo-img {
    border: 5px solid white;
    border-radius: calc(400px / 2);
    width: 400px;
    position: absolute;
    left: 65vw;
    top: 15%
}

.group {
    margin-top: 100px;
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: transparent;
    margin: 0px 110px 10px 110px;
}

a {
    text-decoration: none;
    color: yellow;
}

.scl-abt-logo {
    height: 60px;
    margin: 10px
}

.scl-buttons {
    position: absolute;
    left: 71.7vw;
    top: 550px;
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.clnt-wrap {
    display: flex;
    margin: 50px;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
}

.srvc-wrap {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    align-content: center;
    background-color: white;
}

.news-head {
    color: black;
    background-color: transparent;
    font-size: 58px;
    text-align: center;
    padding-top: 80px;
}

.contact {
    color: white;
    font-size: 28px;
    resize: none;
    align-self: center;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin: 0 auto;
}

div {
    margin-bottom: 15px;
}

label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    align-self: center;
    text-align: center;
}

input,
textarea {
    color: white;
    align-self: center;
    text-align: center;
    margin-top: 5px;
    width: 550px;
    height: 50px;
    font-size: 25px;
    background-color: transparent;
    padding: 5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-color: white;
    border-width: 3px;
}

textarea {
    width: 80%;
    height: 150px;
    resize: none;
}

.contact-button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.message-count{
    position: relative;
    left: 10%;
}

.contact-button:hover {
    background-color: #45a049;
}

.newsletter-container{
    position: relative;
    overflow: hidden;
    width: 70%;
}

.newsletter-image {
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
}

.newsletter-text{
    text-align: center;
    font-size: 42px;
    color: white;
}

.newsletter-overlay {
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0,.5);
    cursor: pointer;
    display: none;
}

.newsletter-container:hover .newsletter-overlay{
    display:flex;
    opacity: 1;
}

.client-title{
    color:white;
    font-size: 60px;
    text-align: center;
    margin-top: 25px;
}

@media only screen and (max-width:480px) {
    .plrs-container{
        min-height: 220px;
    }
    .plrs-rect{
        width: 35%;
        height: auto;
        justify-content: space-around;
        margin:0px;
        padding: 0px;
    }
    .plr-title{
        font-size: 22px;
    }
    .plr-descr{
        font-size: 12px;
    }
    .plr-icon{
        width: 45px;
        height: 45px;
    }
    .plr-button{
        margin-top: 20px;
        font-size: 15px;
        font-weight: bold;
    }
    .stsf-row{
        flex-direction: column;
    }
    .stsf-col{
        flex-direction: row;
    }
    .stsf-header{
        font-size: 30px;
    }
    .stsf-stat{
        font-size: 32px;
        margin-right: 15px;
    }
    .stsf-title{
        font-size: 14px;
    }
    .abt-header-sub{
        font-size: 33px;
    }
    .company-container{
        padding: 45px 5px 45px 5px;
    }
    .company-title{
        font-size: 34px;
    }
    .simple-row{
        margin:0px;
        flex-direction: column;
    }
    .video-frame{
        position: static;
        height: 400px;
        margin-top: 35px;
        background-color: white;
    }
    .desc-container{
        padding: 5px;
        width: 100%;
        margin:0px;
        margin-right: 0px;
        font-size: 16px;
    }
    .desc-text{
        font-size: 16px;
    }
    .ceo-container{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ceo-title{
        position:absolute;
        top:-10%;
        font-size: 38px;
    }
    .ceo-name{
        font-size: 36px;
        margin-top:1.25%;
    }
    .ceo-text{
        font-size: 16px;
        width: auto;
        padding:0px;
        margin:15px
    }
    .ceo-img{
        margin-top: 6%;
        position:static;
        border-radius: calc(180px / 2);
        width: 180px;
    }
    .simple-column{
        padding-left: 0px;
        width: 100%;
        align-items: center;
    }
    .scl-buttons{
        position: static;
        height: auto;
    }
    .scl-abt-logo{
        height: 50px;
    }
    .team-title{
        font-size: 38px;
        padding:0px;
    }
    .clnt-wrap{
        margin:10px;
    }
    .client-title{
        font-size: 32px;
    }
    .brand-title{
        font-size: 28px;
        padding:10px 5px 0px 5px
    }
    .brand-carousel-L{
        height: 300px;
        margin:25px
    }
    .brand-carousel-S{
        height: 250px;
        margin: 25px;
    }
    .srvc-wrap{
        margin-top: 10px;
    }
    .newsletter-container{
        width: 100%;
    }
    .newsletter-text{
        font-size: 22px;
    }
    .news-head{
        padding-top: 25px;
        font-size: 34px;
    }
    label{
        font-size: 22px;
    }
    input{
        height: fit-content;
        width: 200px;
        font-size: 15px;
    }
    textarea{
        width: 300px;
        font-size: 15px;
    }
    .message-count{
        font-size: 15px;
    }
    
}